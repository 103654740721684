<template>
    <div class="main-container">
      <h5 class="pageTitle">기수 조회</h5>
      <mdb-container class="search-container">
        <mdb-row class="search-row">
          <mdb-col class="search-col">
            <select v-model="searchField" class="search-select">
              <option value="batch_name">기수 명</option>
              <option value="name">이름</option>
              <option value="phone">전화번호</option>
            </select>
            <input v-model="searchQuery" type="text" placeholder="검색어를 입력하세요." class="search-input" />
            <button class="btn-search" @click="searchBatchList">검색</button>
            <button class="btn-register" @click="goToRegister">등록</button>
            <button class="btn-update" @click="goToUpdate">수정</button>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>기수 명</th>
            <th>이름</th>
            <th>전화번호</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="paginatedBatches.length === 0">
            <td colspan="4">데이터가 없습니다.</td>
          </tr>
          <tr v-for="(batch, index) in paginatedBatches" :key="batch.id" class="clickable-row" @click="goToUpdate(batch.batch_name)">
            <td>{{ index + 1 + (currentPage - 1) * perPage }}</td>
            <td>{{ batch.batch_name }}</td>
            <td>{{ batch.name }}</td>
            <td>{{ batch.phone }}</td>
          </tr>
        </tbody>
      </table>
      
      <div class="pagination">
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import { mdbCol, mdbContainer, mdbRow } from "mdbvue";
  import Swal from 'sweetalert2';
  import axios from "@/axios";

  export default {
    components: {
      mdbContainer,   
      mdbRow,
      mdbCol
    },
    data() {
      return {
        searchField: "batch_name",
        searchQuery: "",
        batchList: [],           
        currentPage: 1,
        perPage: 10,
      };
    },
    watch: {
      searchQuery() {
        this.currentPage = 1;
      }
    },
    computed: {
      filteredBatches() {
          const query = this.searchQuery.trim().toLowerCase();
          return this.batchList.filter(batch => {
            const fieldValue = batch[this.searchField]?.toString().toLowerCase() || "";
            return fieldValue.includes(query);
        });
        // return this.batchList.filter(batch => {
        //   const fieldValue = batch[this.searchField] || ""; // undefined 방지
        //   return String(fieldValue).toLowerCase().includes(this.searchQuery.toLowerCase());
        // });
      },
      totalPages() {
        return Math.ceil(this.filteredBatches.length / this.perPage);
      },
      paginatedBatches() {
        const start = (this.currentPage - 1) * this.perPage;
        return this.filteredBatches.slice(start, start + this.perPage);
      },
    },
    methods: {
     
      async fetchBatchList() {
        const tokenData = JSON.parse(sessionStorage.getItem('token'));
        const token = tokenData ? tokenData.access_token : '';

        const config = {
            method: 'get',
            url: '/users/batch-list',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            },
        };

       
        try {
            const response = await axios(config);
            console.log("API 응답 데이터:", response.data); 
            this.batchList = response.data.flatMap(batch => 
              batch.users.map(user => ({
                batch_name: batch.batch,  // 배치 정보 추가
                name: user.name,
                phone: user.phone,
                uuid: user.uuid
              }))
            );

            console.log("배치 리스트:", this.batchList); 
        } catch (error) {
            console.error('Error fetching batch list:', error);
            Swal.fire('Error', 'Failed to fetch batch list', 'error');
        }
      },  
      searchBatchList() {
        this.resetPagination();
        this.fetchBatchList();
      },
      resetPagination() {
        this.currentPage = 1;
      },
      goToRegister() {
        this.$router.push("/batch-create");
      },
      goToUpdate(batchName) {
        this.$router.push({
          path: '/batch-update', 
          query: {batch_name: batchName}
        });
      },
      prevPage() {
        if (this.currentPage > 1) this.currentPage--;
      },
      nextPage() {
        if (this.currentPage < this.totalPages) this.currentPage++;
      },
    },
    mounted() {
      this.fetchBatchList();
    },
  };
  </script>
  
  <style scoped>
  .main-container {
    padding: 20px;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .search-container {
    padding: 25px;
  }
  .search-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .search-col {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .search-select {
    height: 38px;
    border-radius: 3px;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .search-input {
    height: 38px;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .btn-search, .btn-register  {
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
  }

  .btn-update{
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #ffffff;
  }
  
  .btn-search {
    background-color: #e6f2ff;
    margin-right: 10px;
  }
  .btn-register {
    background-color: #ffc107;
    margin-right: 10px;
  }
  .btn-update {
    background-color: #337dbe;
  }
 

  table {
    width: 80%;
    border-collapse: collapse;
    margin: auto;
    text-align: center;
  }
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
    text-align: center;
  }
  th {
    background-color: #e3e4eb;
    height: 55px;
  }
  tr {
    height: 50px;
    cursor: pointer;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  </style>
  